<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title)" size="lg">
        <!-- // TODO: Use global confirm -->
        {{ $t(op_confirm.message) }}
        <template #footer>
          <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
          <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
          <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
        </template>
      </CModal>

      <CModal :show.sync="modalEmail.show" :centered="true" :title="$t('Email control')" size="lg">
        <template v-if="modalEmail.show">
          <ModalEmail :entity="modalEmail.entity" :recipients="modalEmail.recipients" :contact_types="modalEmail.contact_types" :building_options="modalEmail.building_options" :config="{ send_button: 'Send certificate' }" subject_prefix="" @modalUpdated="onModalEmailUpdated" @parentHide="modalEmail.show = false" />
        </template>
        <template #footer-wrapper><span></span></template>
      </CModal>

      <CCard no-header>
        <CCardBody>
          <h3>{{ $t('Generate good standing certificate') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <!-- // TODO: Large screen side by side ??? -->
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="building" label="Building" v-model="form.building_id" :options="building_list" @input="onChangeBuilding" />
              <AMultiSelect :disabled="!form.building_id" :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="owner" label="Owner" v-model="form.owner_id" :options="form.building_id ? buildings[form.building_id].owners : []" :key="form.building_id" @input="onChangeOwner" />
              <AMultiSelect :disabled="!form.owner_id" :horizontal="{ input: 'col-sm-3 col-lg-2' }" name="month_id" label="Month" v-model="form.month_id" :options="form.building_id && form.owner_id ? month_list[`${form.building_id}_${form.owner_id}`] : []" :key="`${form.building_id}_${form.owner_id}`" @input="onChangeMonth" />
              <hr />
              <AInput label="Parking lot" v-model="form.parking_lot" />
              <AInput label="Useful room" v-model="form.useful_room" />
              <ATextArea label="Observations" v-model="form.observations" helper="* El texto de las observaciones quedará impreso en el paz y salvo generado." />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton :disabled="!is_valid" class="mr-2" color="info" @click="onConfirm('preview')">{{ $t('Preview certificate') }}</CButton>
            <CButton :disabled="!is_valid" class="mr-2" color="primary" @click="onConfirm('download')">{{ $t('Generate certificate') }}</CButton>
            <CButton :disabled="!is_valid" color="secondary" @click="onConfirm('email')">{{ $t('Email certificate') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import * as utils from '@/app/_utils/global-utils'
import formMixin from '@/app/views/_mixins/form-mixin'
import { DEBUG } from '@/config/config'

export default {
  name: 'GoodStandingCertificateForm',
  mixins: [formMixin],
  computed: {
    is_valid() {
      return this.form.building_id && this.form.owner_id && this.form.month_id
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: {},
      months: {},
      buildings: {},

      // Lists
      month_list: {},
      owner_list: {},
      building_list: [],

      filename: '',
      modalEmail: {
        show: false,
        email: false,
        button: true,
        prefix: '',
        invoice: false,
        recipients: [],
        building_options: false
      },

      op_confirm: { show: false, title: 'Generar certificado ?', message: '', value: {} }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const self = this
      self.$http
        .get('admin/certificates/good_standing/create') // + (this.$route.query.duplicate ? '?duplicate=true' : ''), { _lists: 'contact_types,property_units,buildings,apply_options' })
        .then(response => {
          //self.prepareHelpers(response.data._lists)
          self.parseData(response.data)
          //self.parseView()
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    parseData(data) {
      this.buildings = {}
      this.month_list = {}
      this.owner_list = {}

      const current_owners = {}
      for (const current_owner of data.current_owners) {
        current_owners[current_owner.building_id] = current_owner
      }

      const building_list = []
      for (const building of data.buildings) {
        let index = 0
        const owners = {}
        for (const owner of building._owners) {
          const pos = owner.property_contact_id === (current_owners[building.id] || {}).property_contact_id ? ' (Propietario actual)' : ' (Propietario anterior)' // TODO: create function (Avoid object non-found) ?
          const month = owner.month_id
          //owners[owner.contact.id] = { months: {}, index, id: owner.contact.id, name: owner.contact.name + (index ? ' (Anterior)' : ' (Actual)'), ...(owners[owner.contact.id] || {}) }
          owners[owner.contact.id] = { months: {}, index, id: owner.contact.id, name: owner.contact.name + pos, ...(owners[owner.contact.id] || {}) }
          const invoice = building._invoices.find(item => item.month_id === owner.invoice_month_id) //month)

          // TODO: Validate overdue
          //if (invoice && !invoice.overdue_value) {
          owners[owner.contact.id].months[month] = { month, overdue_value: invoice.overdue_value, months: [], ...(owners[owner.contact.id].months[month] || {}) }
          owners[owner.contact.id].months[month].months.push(owner.month_id)
          //}
          this.owner_list[owner.contact.id] = owner
          index++
        }

        // TODO: Improve this
        const owner_list = []
        for (const owner of utils.order(Object.values(owners), 'index')) {
          const months = []
          for (let month in owner.months) {
            month = owner.months[month]
            months.push(month.month)
            this.months[`${building.id}_${owner.id}`] = month
            this.month_list[`${building.id}_${owner.id}`] = [...(this.month_list[`${building.id}_${owner.id}`] || []), { id: month.month, name: this.getPeriodName(month.month) + (month.overdue_value ? ' (Overdue)' : ''), disabled: !!month.overdue_value, helper: month.overdue_value ? 'Overdue' : false }]
          }
          owner.months = months
          owner_list.push(owner)
        }

        building.owners = owner_list
        building_list.push({ id: building.id, name: building.identifier })

        this.buildings[building.id] = building
      }

      this.building_list = building_list
    },

    onChangeBuilding() {
      this.$set(this.form, 'owner_id', '')
      this.onChangeOwner()
    },

    onChangeOwner() {
      this.$set(this.form, 'month', '')
    },

    onChangeMonth() {
      //console.log(this.months[`${this.form.building_id}_${this.form.owner_id}`])
    },

    onConfirm(type) {
      const month = this.months[`${this.form.building_id}_${this.form.owner_id}`]
      const building = this.buildings[this.form.building_id]

      this.filename = this.property.short_name + ` - Paz y Salvo - ${building.identifier}.pdf`

      if (month.months.length === 12) {
        if (type === 'email') this.onEmail()
        else this.onSubmit(type)
      } else {
        this.op_confirm.show = true
        this.op_confirm.type = type
        //this.op_confirm.index = index
        //this.op_confirm.id = id

        // TODO: english
        this.op_confirm.message = 'Desea generar el certificado?'
      }
    },

    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) {
        if (this.op_confirm.type === 'email') this.onEmail()
        else this.onSubmit(this.op_confirm.type)
      }
    },

    onEmail() {
      const contact = this.owner_list[this.form.owner_id]
      contact.contact.emails.forEach(email => {
        email.checked = false
      })

      this.modalEmail.recipients = [
        { ...contact, readonly: true, type_id: 1, type: 'to', checked: false, emails: contact.contact.emails },
        { name: 'Send copy to', emails: [{ email: '', checked: false }], type: 'others' },
        { name: 'Admon copy', emails: [{ email: this.property.email, checked: false }], readonly: true, type: 'others', admin: true },
        { name: 'Copy me', emails: [{ email: this.loggedUser.email, checked: false }], readonly: true, type: 'others' }
      ]

      this.modalEmail.prefix = this.$t('Certificate')
      this.modalEmail.entity = { id: this.form.id, type: 'certificate', origin: 'Certificate', subject: this.form.subject, file_name: this.filename, file_type: 'pdf' }
      this.modalEmail.building_options = this.lists
      this.modalEmail.show = true

      //this.modalEmail.contact_types = this.view_helpers.contact_types
      this.modalEmail.contact_types = { 1: { name: 'Propietario Principal' } } // TODO: from backend !!
    },

    onModalEmailUpdated(params) {
      this.modalEmail.show = false
      this.modalEmail.params = params
      this.onSubmit('email')
    },

    onSubmit(type) {
      const self = this
      self.form.certificate = 'good_standing'
      if (type === 'download') {
        self.$http
          .getBlob(`admin/certificates/${this.form.building_id}/download`, self.form) // TODO: on service ?
          .then(response => {
            self.showAlert('Certificate generated')

            // TODO: Enable preview ?
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            console.log(response.data)

            fileLink.href = fileURL
            fileLink.setAttribute('download', this.filename)
            document.body.appendChild(fileLink)

            fileLink.click()
          })
          .catch(error => {
            console.error(error)
            self.showAlert(`There was an error creating the certificate.`, 'danger')
          })
      } else {
        // TODO: create a function
        let params = ''
        if (type === 'email') {
          if (!this.modalEmail.params.checked.length) return

          const recipients = { to: [], cc: [], others: [] }
          for (const recipient of this.modalEmail.params.checked) {
            recipients[recipient.type].push(recipient.email)
          }
          params = `?prefix=${this.modalEmail.params.prefix}&` + new URLSearchParams(recipients).toString()
        }
        self.$http
          .get(`admin/certificates/${this.form.building_id}/${type}` + params, self.form)
          .then(response => {
            if (type === 'preview') {
              self.showAlert('Certificate generated')
              window.open(response.data.signed_url, this.filename)
            } else {
              self.showAlert('Certificate sent')
            }
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
    }
  }
}
</script>
